<template>
  <!--  账号输入登录-->
  <div class="bodyContent">
    <div class="login_box">
        <div class="login_panel">
          <router-link :to="{ name: 'auth'}">
            <div class="login_close"></div>
          </router-link>
          <div class="login_title">
            <img src="~@/assets/img/logo.png" alt="">
            <p>输入账号进行安全登录</p>
          </div>
          
        </div>
        <div class="login_content">
          <a-form
            id="formLogin"
            ref="formLogin"
            :form="form"
            @submit="handleSubmit"
          >

            <a-form-item>
              <a-input size="large" type="text" placeholder="手机号" v-decorator="['mobile', {rules: [{ required: true, pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号' }], validateTrigger: 'change'}]">
                <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
              </a-input>
            </a-form-item>

            <a-row :gutter="8">
              <a-col :span="15">
                <a-form-item>
                  <a-input size="large" type="text" placeholder="验证码" v-decorator="['captcha', {rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur'}]">
                    <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col :span="8" >
                <a-button
                  size="large"
                  type="primary" 
                  :disabled="state.smsSendBtn"
                  @click.stop.prevent="getCaptcha"
                  v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"
                ></a-button>
              </a-col>
            </a-row>


            <a-form-item style="margin-top:24px">
              <a-button
                size="large"
                type="primary"
                htmlType="submit"
                class="login-button"
                :loading="state.loginBtn"
                :disabled="state.loginBtn"
              >登录</a-button>
            </a-form-item>

          </a-form>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getSmsCaptcha,LoginByVCode } from '@/network/user/login'

export default {
  data () {
    return {
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      }
    }
  },
  created () {
  },
  methods: {
    // handleSubmit(e) {
    //   //阻止表单提交
    //   e.preventDefault();
    //   this.$router.push('mainTab')
    // },
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
      } = this
      state.loginBtn = true
      const validateFieldsKey =  ['mobile', 'captcha']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          var parameter={
            phone: values.mobile,
            vCode: values.captcha,
          }
          LoginByVCode(parameter)
            .then((res) => this.loginSuccess(res))
            .catch(err => {
              console.log(err)
              this.$notification['error']({
              message: '提示',
              description: err.msg,
              duration: 4.5
            })
            })
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },

    //发送验证码
    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = this.$message.loading('验证码发送中..', 0)
          var parameter={
            mobile: values.mobile,
          }
          getSmsCaptcha(parameter).then(res => {
            console.log(res)
            setTimeout(hide, 1000)
            this.$notification['success']({
              message: '提示',
              description: '验证码发送成功，请注意查收！',
              duration: 8
            })
          }).catch(err => {
            console.log(err)
            setTimeout(hide, 1)
            clearInterval(interval)
            state.time = 60
            state.smsSendBtn = false
            this.requestFailed(err)
          })
        }
      })
    },

    /* 登录成功调用该方法 */
    loginSuccess (res) {
      console.log(res)
      this.$router.push('mainTab')
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`,
          duration: 3
        })
      }, 1000)
      this.isLoginError = false
    },


    requestFailed (err) {
      this.isLoginError = true
      this.$notification['error']({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .bodyContent{
    padding: 0 20px 90px;
  }
  /*登录框*/
  .login_box {
    /* 居中 */
    left: 0;
    right: 0;
    margin: auto;

    width: 400px;
    height: 540px;
    // height: 60%;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 2px 10px #999;

    .login_panel {
      width: 100%;
      height: 40%;

      .login_close {
        width: 64px;
        height: 64px;
        background: url(~@/assets/img/qrcode.png) no-repeat right top;
        background-size: 100% 100%;
        border-top-right-radius: 5px;
        cursor: pointer;
        z-index: 99;
        float: right;
      }

      .login_title{
        text-align: center;
        img {
          height: 96px;
          width: 96px;
          border-radius: 50%;
          padding: 10px;
          border: 3px solid #d7e8fc;
          margin-top: 60px;
          margin-left: 60px;
        }
        p{
          margin-top: 15px;
          color: #999999;
          font-size: 15px;
        }
      }
    }
    
    .login_content{
      width: 100%;
      height: 60%;
      form{
        padding: 0 45px;
      }

      input {
        display: inline;
        height: 42px;
        padding: 0 5%;
        line-height: 42px;
        font-size: 14px;
        color: #333333;
        border-radius: 4px;
        outline: 0;
        border: 0;
        width: 90%;
        background: #d7e8fc;
      }

      .bt {
        margin-top: 11px;
        width: 100%;
        color: #ffffff;
        background: #379df6;
        cursor: pointer;
      }
      .bt:hover {
        background-color: #2f86f6;
      }

      .login-button {
        padding: 0 15px;
        font-size: 16px;
        height: 40px;
        width: 100%;
      }
    }
  }
</style>
